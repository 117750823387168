import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import FrontPage from '@/views/frontpage/FrontPage.vue'
Vue.use(VueRouter)



const routes = [

  {
    // 訪問
    path: '/Login',
    name: 'Login',
    component: () => import('../views/login/IndexView.vue')
  },
  {
    path: '/Operation',
    name: 'Operation',
    component: () => import('../views/Operationlog/OperationView.vue')
  },
  //搜尋日誌 
  {
    path: '/Searchlog',
    name: 'Searchlolg',
    component: () => import('../views/Searchlog/SearchlogView.vue')
  },
  // 錯誤日誌
  {
    path: '/Errorlog',
    name: 'Errorlog',
    component: () => import('../views/Errorlog/ErrorlogView.vue')
  },
  // 登入日誌
  {
    path: '/Loginlog',
    name: 'Loginlog',
    component: () => import('../views/Loginlog/LoginlogView.vue')
  },
  // 用戶管理
  {
    path: '/Usermanage',
    name: 'Usermanage',
    component: () => import('../views/Usermanage/UsermanageView.vue')
  },
  // 設備管理
  {
    path: '/Devicemanage',
    name: 'Devicemanage',
    component: () => import('../views/Devicemanage/DevicemanageView.vue')
  },
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router 